<template lang="pug">
.social-icons
    transition-group(name="slide-in-top" appear)
        template(v-for="(item, index) in items" :key="index")
            a.social-link(:class="$route.name == 'home' ? 'social-link--large' : ''" :href="item.href" :title="item.title" :style="`transition-delay:${calcDelay(index)}ms`" target="_blank" rel="noopener noreferrer")
                span.sr-only {{ item.title }}
                img(v-svg-inline :src="require(`../assets/vector/${item.svg}`)" alt="" aria-hidden="true")
</template>

<script>
import checkBreakpoint from '@/js/mixins/checkBreakpoint.js'

export default {
    name: 'SocialIcons',
    props: {
        appearDelay: {
            type: Number,
            default: 0
        }
    },
    mixins: [checkBreakpoint],
    data() {
        return {
            items: [
                {title: 'Youtube', href: this.$globals.socials.youtube, svg: 'youtube.svg'},
                {title: 'Twitch', href: this.$globals.socials.twitch, svg: 'twitch.svg'},
                {title: 'Instagram', href: this.$globals.socials.instagram, svg: 'instagram.svg'},
                {title: 'Patreon', href: this.$globals.socials.patreon, svg: 'patreon.svg'},
                {title: 'Spotify', href: this.$globals.socials.spotify, svg: 'spotify.svg'}
            ]
        }
    },
    methods: {
        calcDelay(index) {
            return index * 100 + parseInt(this.appearDelay)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.social-icons {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: $quick-transition;
}

.social-link {
    display: flex;
    height: 32px;
    width: 32px;
    margin: 0 8px;
}

.social-link--large {
    @include at(laptop) {
        height: 40px;
        width: 40px;
        margin: 0 16px;
    }
}

.social-link svg {
    fill: $bright-text;
    transition: .2s;
    opacity: .9;

    &:hover {
        fill: $button-bg-hover;
        opacity: 1;
        transform: scale(1.1);
    }
}

.social-link:active svg {
    fill: $button-bg-active;
}
</style>