export const checkBreakpoint = {
    data() {
        return {
            width: window.innerWidth
        }
    },
    mounted() {
        window.addEventListener("resize", this.viewPortWidth)
    },
    unmounted() {
        window.removeEventListener("resize", this.viewPortWidth)
    },
    methods: {
        viewPortWidth(relation, breakPoint) {
            this.width = window.innerWidth;

            if(relation == '>=') {
                if(this.width >= breakPoint) {
                    return true;
                } else {
                    return false;
                }
            } else if (relation == '<') {
                if(this.width < breakPoint) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
}

export default checkBreakpoint