
let todayMidnight = new Date();
todayMidnight.setHours(0,0,0,0);
const phpPath = process.env.VUE_APP_API_PATH;

export const getEvents = {
    data() {
        return {
            eventsData: null,
            eventsDataLoading: true,
            todayMidnightISO: todayMidnight.toISOString()
        }
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            fetch(`${phpPath}/getEvents.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    orderBy: 'startTime',
                    maxResults: 15,
                    timeMin: todayMidnight.toISOString()
                }),
            })
            .then(response => response.json())
            .then(data => {
                this.eventsData = data.items;
                this.eventsDataLoading = false
            })
            .catch(error => {
                console.error('Error fetching events data:', error);
            });

        },
    }
}

export default getEvents