import { createApp, reactive } from 'vue'
import App from '@/App.vue'
import { createI18n } from 'vue-i18n/index'
import Vue3TouchEvents from "vue3-touch-events";
import router from '@/js/router.js'
import VueCookies from 'vue3-cookies'
import en from '@/i18n/en.json';
import hu from '@/i18n/hu.json';
import enDate from '@/i18n/en-date.json'
import huDate from '@/i18n/hu-date.json'
import { useAccordion } from "vue3-rich-accordion";
import masonry from 'vue-next-masonry';
import VueSvgInlinePlugin from "vue-svg-inline-plugin";

let userLang = navigator.language || navigator.userLanguage;

const i18n = createI18n({
    legacy: false,
    // TODO ha en- akkor en
    locale: userLang == 'hu' ? 'hu' : 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
        en: en,
        hu: hu
    },
    datetimeFormats: {
        en: enDate,
        hu: huDate
    }
});

const globals = reactive({
    playHomeVideoBool: true,
    videoPlaying: "",
    socials : {
        youtube: "https://www.youtube.com/c/BeatrixGuitar",
        twitch: "https://www.twitch.tv/beatrixguitar",
        instagram: "https://www.instagram.com/beatrixguitar/",
        patreon: "https://www.patreon.com/beatrixguitar",
        spotify: "https://open.spotify.com/artist/2Ps3pKjZbc1u2LwFLA0UbX?si=9RPKVx2kSkmYW8yun2DuLw",
        substack: "https://beatrixguitar.substack.com/",
        appleMusic: "https://music.apple.com/us/album/journey/1657700262",
        amazon: "https://www.amazon.com/Journey-Kovács-Beatrix/dp/B0BNQG9ML3"
    }
  });

const app = createApp(App)
    .use(router)
    .use(i18n)
    .use(VueCookies, {
        path: "/",
        domain: ""
    })
    .use(Vue3TouchEvents)
    .use(useAccordion)
    .use(masonry)
    .use(VueSvgInlinePlugin)

    app.config.globalProperties.$globals = globals;

    app.mount('#app')
