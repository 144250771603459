<template lang="pug">
nav.navigation(v-touch:swipe.top="navigationToggleHandler", :class="[navigationOpen ? 'navigation--open' : '', scrolledDown ? 'navigation--scrolled': '']")
    transition(name="quick-fade")
        span.navigation-blanket(v-if="navigationOpen", @click="navigationToggleHandler")
    .navigation-container
        .navigation-bottom(v-touch:swipe.bottom="navigationToggleHandler")
            span.current-page {{ $t(`globals.${this.$route.name}`) }}
            button.navigation-toggle(type="button", @click="navigationToggleHandler")
                .sr-only {{ $t("globals.menu")}}
                .hamburger
                    span
                    span
                    span
                    span
                span.sr-only {{ $t("globals.langSwitch") }}
            label.switch.switch--labelled.lang-switch(for="ls" :title="$t('globals.langSwitch')")
                input#ls(type="checkbox" :lang="$root.$i18n.locale == 'hu' ? 'en' : 'hu'" @click="switchLanguages" v-model="langSwitch")
                span.slider
                .switch-label
                    span EN
                    span HU 
            transition(name="quick-fade" mode="out-in")
                label.switch.switch--labelled.video-switch(v-if="this.$route.name == 'home'" for="vs" :title="videoSwitch ? $t('home.videoPlay') : $t('home.videoStop')")
                    input#vs(type="checkbox" @click="homeVideoHandler" v-model="videoSwitch")
                    span.slider
                    .switch-label
                        img(v-svg-inline src="../assets/vector/play.svg" alt="" aria-hidden="true")
                        img(v-svg-inline src="../assets/vector/stop.svg" alt="" aria-hidden="true")
                span.name(v-else) {{ $t("home.headline") }}
        router-link.router-link(@click="navigationToggleHandler", to='/') {{ $t("globals.home") }}
        router-link.router-link(@click="navigationToggleHandler", to='/bio') {{ $t("globals.bio") }}
        router-link.router-link(@click="navigationToggleHandler", to='/events') {{ $t("globals.events") }}
        router-link.router-link(@click="navigationToggleHandler", to='/lessons') {{ $t("globals.lessons") }}
        router-link.router-link(@click="navigationToggleHandler", to='/music') {{ $t("globals.music") }}
        a.router-link(href="https://beatrixguitarstudio.myshopify.com/" rel="noopener noreferrer" target="_blank") {{ $t("globals.shop") }}
            img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")
        .navigation-buttons
            button.button(:data-cal-link="this.$root.$i18n.locale == 'hu'? 'beatrixguitar/mesterkurzus' : 'beatrixguitar/guitarlesson'" data-cal-namespace="" data-cal-config='{ "layout":"month_view"}') {{ $t("globals.bookingButton") }}
</template>

<script>

export default {
    name: "Navigation",
    data() {
        return {
            navigationOpen: false,
            scrolledDown: false,
            langSwitch: localStorage.getItem("lang") == 'hu' ? true : false,
            videoSwitch: (localStorage.getItem('playHomeVideo') == 'true' || localStorage.getItem('playHomeVideo') === null) ? false : true
        }
    },
    mounted() {
        this.navigationOpen = false
        window.addEventListener('resize', this.resetnavigation)
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        this.navigationOpen = false
        window.removeEventListener('resize', this.resetnavigation)
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
        const scrolled = window.scrollY >= 20;
            if (scrolled !== this.scrolledDown) {
                this.scrolledDown = scrolled;
            }
        },
        switchLanguages() {
            if (this.$root.$i18n.locale == 'hu') {
                this.$root.$i18n.locale = 'en'
                localStorage.setItem('lang','en')
            } else {
                this.$root.$i18n.locale = 'hu'
                localStorage.setItem('lang','hu')
            }
            document.documentElement.setAttribute("lang", this.$root.$i18n.locale)
        }, 
        navigationToggleHandler() {
            if(window.innerWidth < 768) {
                this.navigationOpen = !this.navigationOpen;

                if(this.navigationOpen) {
                    document.body.setAttribute('class', 'no-scroll-mobile')
                } else {
                    document.body.removeAttribute('class')
                }
            }
        },
        resetnavigation() {
            if(window.innerWidth >= 768) {
                this.navigationOpen = false
                document.body.removeAttribute('class')
            }
        },
        homeVideoHandler() {
            this.$globals.playHomeVideoBool = !this.$globals.playHomeVideoBool
            localStorage.setItem('playHomeVideo', this.$globals.playHomeVideoBool)
        }
    }
}
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '@/scss/_utilities';

.navigation {
    z-index: $layer3;
    position: fixed;
    transition: $quick-transition;
    width: 100%;
    height: 45px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 67px;
        background: linear-gradient(to top, rgba(0,0,0,0) 0%, #000000a3 100%);
    }

    @include at(laptop) {
        height: auto;
    }
}

.navigation--scrolled {
    background-color: $contentBg-green;
    box-shadow: inset 0px -1px 0px 0px $outline;
}

.navigation-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: right;
    padding: $contentPaddingMobile;
    transform: translateY(-100%);
    transition: $quick-transition;
    transition-delay: $quick-transition;
    box-sizing: border-box;
    max-width: calc(1440px + 32px + 32px);
    margin: 0 auto;

    @include at(laptop) {
        flex-direction: row;
        padding: 8px $contentPaddingLaptop;
        transform: translateY(0);
    }

    @include at(laptopLarge) {
        padding: 8px $contentPaddingLaptop;
    }

    @include at(desktop) {
        padding: 8px $contentPaddingLaptop;
        max-width: $maxContentWidthDesktop;
    }
}

.navigation-container .navigation-buttons {
    @include at(laptop) {
        margin-left: 24px;
    }
}

.navigation--open .navigation-container {
    transform: translateY(0);
    background-color: $contentBg-green;
    border-color: $outline;
}

.navigation-blanket {
    height: 100vh;
    background: $overlay;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $layer0;
}

.router-link {
    box-shadow: inset 0px 0px 0px 0px $button-bg-hover;
    font: 300 1rem/1.4 'Montserrat', 'Arial', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    transition: $quick-transition;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    &:hover {
        box-shadow: inset 0px -1px 0px 0px $button-bg-hover;
    }

    @include at(laptop) {
        margin: 0 8px;
    }
    
    @include at(laptopLarge) {
        font-size: 1.2rem;
    }
}

.router-link-active {
    box-shadow: inset 0px -1px 0px 0px $button-bg-hover;
    color: $button-bg-hover;
    pointer-events: none;
}

.router-link svg {
    width: 24px;
    height: 24px;
    fill: $bright-text;
    transition: $quick-transition;
}

.router-link:active svg {
    fill: $button-bg-active;
}

.navigation-bottom {
    align-items: center;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 56px 1fr;
    bottom: -45px;
    padding: 0 16px;
    position: absolute;
    width: 100%;
    height: 45px;

    @include at(laptop) {
        position: static;
        border: 0;
        padding: 0;
        margin-right: auto;
        width: auto;
        height: auto;
        grid-template-columns: auto auto;
        align-items: center;
    }
}

.navigation--open .navigation-bottom {
    border-color: transparent;
}

.beatrixguitar--home .navigation-bottom {
    background-color: transparent;
    border-color: transparent;
}

.current-page {
    font: 18px/1.4 'Montserrat', 'Arial', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    opacity: 1;
    transition: $quick-transition $quick-transition;
    justify-self: start;

    @include at(laptop) {
        display: none;
    }
}

.navigation--open .current-page {
    opacity: 0;
    transition-delay: 0s;
}

.navigation-toggle {
    background-color: transparent;
    transition: $quick-transition;
    border: 0;
    border-top: 0;
    color: $bright-text;
    cursor: pointer;
    height: 48px;
    width: 56px;
    padding: 4px;

    @include at(laptop) {
        display: none;
    }
}

.navigation--open .navigation-toggle {
    background-color: $contentBg-green;
    border: 1px solid $outline;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.name {
    display: none;

    @include at(desktop) {
        display: block;
        font: 300 1.2rem/1.4 'Montserrat';
        text-transform: uppercase;
        margin-left: 16px;
    }
}

.lang-switch {
    position: absolute;
    right: $contentPaddingMobile;
    top: 50%;
    transform: translateY(-50%);
    transition: $quick-transition $quick-transition;
    border-radius: math.div($rootBorderRadius, 2);

    @include at(laptop) {
        position: relative;
        left: auto;
        right: auto;
        transform: none;
        top: auto;
    }
}

.navigation--open .lang-switch {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s;
}

.video-switch {
    margin-left: 16px;
    display: none;

    @include at(laptopMedium) {
        display: block;
    }
}

/* hamburger animation */
.hamburger {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 0 auto ;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  zoom: .5;
}

.hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $bright-text;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  transition-delay: $slow-transition;
}

.hamburger span:nth-child(1) {
  top: 0px;
}

.hamburger span:nth-child(2),
.hamburger span:nth-child(3) {
  top: 18px;
}

.hamburger span:nth-child(4) {
  top: 36px;
}

.navigation--open .hamburger span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navigation--open .hamburger span:nth-child(2) {
  transform: rotate(45deg);
}

.navigation--open .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
}

.navigation--open .hamburger span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navigation .button {
    padding: 8px;

    @include at(laptopLarge) {
        padding: 16px;
    }
}

</style>



