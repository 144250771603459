<template lang="pug">
.beatrixguitar(:class="`beatrixguitar--${currentPage}`")
    Navigation
    main.main
        router-view(v-slot="{ Component }" )
            transition(name="quick-fade" mode="out-in")
                component(:is="Component" :key="currentPage + '-page'")
    Footer
</template>

<script>
import Navigation from './components/Navigation'
import Footer from './components/Footer'

export default {
    name: "App",
    components: {
        Navigation,
        Footer
    },
    mounted() {
        //- set lang by cookie value or navigator value
        let langCookieValue = localStorage.getItem('lang')
        langCookieValue ? this.$root.$i18n.locale = localStorage.getItem('lang') : null,
        document.documentElement.setAttribute('lang', this.$root.$i18n.locale)
    },
    computed: {
        currentPage() {
            return this.$route.name;
        }
    }
}
</script>


<style lang="scss">
@import '@/scss/global';

.beatrixguitar {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    @include at(laptop) {
        min-height: calc(100vh - 64px);
    }

    @include at(desktop) {
        min-height: 100vh;
    }
}

.route {
    @include at(laptop) {
        padding-bottom: 64px;
    }
}

main {
    display: grid;
    //margin-top: auto;
    
    @include at(desktop) {
        flex-grow: 1;
    }
}

.route--full-page {
    padding: 45px 0 0;

    @include at(laptop) {
        padding: 64px 0;
    }
}

.route--full-page {
    // subtract
    min-height: calc(100vh - 133px);
    align-items: flex-end;
}

.content {
    position: relative;
}
</style>


