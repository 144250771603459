<template lang="pug">
.home-video
    video.video(ref="homeVideo" autoplay muted loop)
        source(src="@/assets/videos/home_bg.mp4", type="video/mp4")
        | Your browser does not support the video tag.
</template>
    
<script>
export default {
    name: "HomeVideo",
    mounted() {
        // if playHomeVideo is true or does not exists play the video
        this.$globals.playHomeVideoBool = (localStorage.getItem('playHomeVideo') == 'true' || localStorage.getItem('playHomeVideo') === null) ? true : false
        this.playController(this.$globals.playHomeVideoBool)
    },
    watch: {
        '$globals.playHomeVideoBool': function() {
            this.playController(this.$globals.playHomeVideoBool)
        }
    },
    methods: {
        playController(playVideo) {
            const video = this.$refs.homeVideo;
            playVideo ? video.play() : video.pause();
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/global';

.home-video {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
}

.video {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: brightness(.9);

    @include at(laptopLarge) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.play-button {
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1000;
}
</style>
