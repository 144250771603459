<template lang="pug">
transition(name="quick-fade" appear)
    .cookie-bar(v-if="!hasAcceptedCookies")
        p {{ $t("cookiebar.text1") }} 
            a.link(href="https://developer.spotify.com/documentation/embeds/tutorials/creating-an-embed" target="_blank") Spotify
                img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")
            |  {{ $t("cookiebar.text2") }} 
            a.link(href="https://cal.com/" target="_blank") Cal.com
                img(v-svg-inline src="../assets/vector/open.svg" alt="" aria-hidden="true")
            |  {{ $t("cookiebar.text3") }}
        button.button(@click="acceptCookies") {{ $t("cookiebar.button") }}
</template>

<script>
export default {
    name: 'CookieBar',
    data() {
    return {
      hasAcceptedCookies: false
    };
  },
    created() {
        this.checkCookieStatus();
    },
    methods: {
        checkCookieStatus() {
            // Check local storage for cookie acceptance
            if (localStorage.getItem('cookiesAccepted')) {
                this.hasAcceptedCookies = true;
            }
        },
        acceptCookies() {
            // Set local storage item to remember acceptance
            localStorage.setItem('cookiesAccepted', 'true');
            this.hasAcceptedCookies = true;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_utilities';

.cookie-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    position: fixed;
    padding: 8px 16px;
    box-shadow: $button-shadow;
    background-color: rgba($color: #000000, $alpha: .8);
    bottom: 0;
    z-index: $layer1;
    width: 100%;
    left: 0;
    border-top: 1px solid $contentBg-bright;

    @include at(laptop) {
        text-align: left;
        flex-direction: row;
        gap: 16px;
    }

    @include at(laptopLarge) {
        background-color: rgba($color: #000000, $alpha: .6);
        border-radius: $rootBorderRadius;
        border: 1px solid $contentBg-bright;
        bottom: 90px;
        max-width: 880px;
        left: 50%;
        transform: translateX(-50%);
    }
}

p {
    margin-bottom: 0;
}

.button {
    padding: 12px;

    @include at(laptop) {
        flex-basis: 150px;
        margin-left: auto;
    }
}

.link {
    display: inline-flex;
    align-items: center;
}

.link svg {
    width: 20px;
}
</style>